import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header
                style={{
                  height: "120px"
                }}
              >
                <div
                  style={{
                    height: "67px"
                  }}
                >
                  <h1 className="logo">
                    <a href="/" />
                  </h1>
                  <nav>
                    <a href="/site/produkty/" className="link">
                      Produkty
                    </a>
                    <span>| </span>
                    <a
                      href="/site/o-neutrogenie/kim-jestesmy/"
                      className="link"
                    >
                      O Neutrogenie
                    </a>
                    <span>| </span>
                    <a href="/site/bad-piekna/" className="section">
                      Bądź piękna
                    </a>
                    <span>| </span>
                    <a href="/site/see-whats-possible/" className="link">
                      See What's Possible
                    </a>
                    <span>| </span>
                    <a
                      href="/site/skora-z-niedoskonalosciami/"
                      className="link"
                    >
                      Skóra z niedoskonałościami
                    </a>
                    <span>| </span>
                  </nav>
                </div>
                <div
                  style={{
                    "padding-left": "185px",
                    height: "53px"
                  }}
                >
                  <nav
                    className="second"
                    style={{
                      float: "none"
                    }}
                  >
                    <a href="/site/hydroboost/" className="link">
                      Hydro Boost
                    </a>
                    <span>| </span>
                    <a href="/site/skin-detox/" className="link">
                      Skin Detox
                    </a>
                    <span>| </span>
                    <a href="/site/cellular-boost/" className="link">
                      Cellular Boost
                    </a>
                    <span />
                  </nav>
                </div>
              </header>
              <div className="top">
                <h2>Pielęgnacja dłoni</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/site/bad-piekna/">Bądź piękna</a> <span>&gt;</span>
                  Pielęgnacja dłoni
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/site/bad-piekna/test-skory/" className="link">
                Test skóry
              </a>
              <a
                href="/site/bad-piekna/ogolnopolskie-badanie-skory/"
                className="link"
              >
                Badanie Skóry
              </a>
              <a href="/site/bad-piekna/sucha-skora/" className="link">
                Sucha skóra
              </a>
              <a href="/site/bad-piekna/pielegnacja-twarzy/" className="link">
                Pielęgnacja twarzy
              </a>
              <a href="/site/bad-piekna/pielegnacja-ust/" className="link">
                Pielęgnacja ust
              </a>
              <a href="/site/bad-piekna/pielegnacja-stop/" className="link">
                Pielęgnacja stóp
              </a>
              <a href="/site/bad-piekna/pielegnacja-ciala/" className="link">
                Pielęgnacja ciała i regeneracja skóry
              </a>
              <a href="/site/bad-piekna/pielegnacja-doni/" className="current">
                Pielęgnacja dłoni
              </a>
            </div>
          </div>
          <section className="hand">
            <div className="row">
              <div className="row__wrapper">
                <div className="text row__wrapper__text">
                  <h2>Dłonie są wizytówką kobiety</h2>
                  <p>
                    Nasze dłonie codziennie wykonują tysiące czynności – tworzą,
                    pielęgnują, pracują, witają się i żegnają. Są naszą
                    wizytówką i zdradzają nasz wiek. Dlatego o dłonie warto
                    zacząć dbać jeszcze wtedy, gdy są młode.
                    <br /> Jak skutecznie pielęgnować dłonie i zachować jak
                    najdłużej ich młody wygląd? Przeczytaj porady eksperta marki
                    NEUTROGENA® – dr Urszuli Brumer.
                  </p>
                </div>
                <div className="row__wrapper__photo">
                  <img src="/site/assets/hand/author.png" alt />
                </div>
              </div>
            </div>
            <div className="row row-blue">
              <div className="row__wrapper">
                <div className="text">
                  <h2>
                    Dlaczego skóra na dłoniach staje się podrażniona i
                    przesuszona?
                  </h2>
                  <p>
                    Nasze dłonie nieustannie wystawione są na działanie
                    czynników drażniących, takich jak zmienne temperatury. Nasze
                    dłonie nie lubią skrajnych temperatur. Skóra rąk
                    wystawionych na działanie niskiej temperatury – bez ochrony
                    i odpowiedniej pielęgnacji – szybko ulega spierzchnięciu.
                    Dzieje się tak, ponieważ gruczoły łojowe spowalniają swoją
                    pracę i dochodzi do zaburzenia warstwy łojowej płaszcza
                    hydrolipidowego. Staje się on cięższy, bardziej zwarty, co
                    prowadzi do szybszej utraty wody.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    Natomiast niewiele osób zdaje sobie sprawę, że zbyt wysoka
                    temperatura też ma wpływ na kondycję rąk. Ciepłe, suche
                    powietrze sprzyja nadmiernemu parowaniu wody (naturalny
                    proces chłodzenia i utrzymywania równowagi cieplnej
                    organizmu) – na początku z wierzchniej warstwy skóry, czyli
                    naskórka, a następnie z głębszych warstw skóry.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    Wysuszenie skóry dłoni powoduje też mydło. Podczas mycia
                    usuwamy ze skóry nie tylko zanieczyszczenia, ale również
                    pokrywające ją sebum, odpowiedzialne za regulację gospodarki
                    wodnej skóry. Ponadto większość mydeł posiada zasadowe pH –
                    naturalne pH skóry jest kwaśne, bo taki odczyn chroni ją
                    przed różnego rodzaju drobnoustrojami. Zmiana pH na zasadowe
                    sprzyja powstawaniu stanów zapalnych w obrębie skóry, a to
                    przerywa jej ciągłość i sprzyja utracie wody.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row__wrapper">
                <div className="text row__wrapper__text">
                  <h2>Skuteczna pielęgnacji dłoni</h2>
                  <p>
                    <span className="red">
                      Najczęstsze problemy, które dotykają skórę dłoni to:
                    </span>{" "}
                    <br />
                    <br />
                  </p>
                  <ul>
                    <li>
                      <span>utrata elastyczności,</span>
                    </li>
                    <li>
                      <span>szorstkość i przesuszenia,</span>
                    </li>
                    <li>
                      <span>przebarwienia,</span>
                    </li>
                    <li>
                      <span>spierzchnięcie,</span>
                    </li>
                    <li>
                      <span>bolesność i zaczerwienienia.</span>
                    </li>
                  </ul>
                  <p>
                    <br />
                    <span className="red">
                      Aby uniknąć powyższych problemów, wystarczy wprowadzić
                      proste zasady pielęgnacji skóry dłoni:
                    </span>{" "}
                    <br />
                    <span className="red">1.</span> Dbaj o skórę niezależnie od
                    pory roku i codziennie – niech to stanie się Twoją rutyną.
                    <br />
                    <span className="red">2.</span> Kremuj dłonie kilka razy
                    dziennie, najlepiej od 3 do 5 razy dziennie.
                    <br />
                    <span className="red">3.</span> Poza regularnym kremowaniem
                    stosuj peeling – przynajmniej raz w tygodniu.
                    <br />
                    <span className="red">4.</span> Używaj kremów, których
                    działanie jest dłuższe. To, jak nasza skóra zachowuje się
                    godzinę po użyciu kremu, jest miarą skuteczności jego
                    działania.
                    <br />
                    <span className="red">5.</span> Kiedy wykonujesz manicure
                    hybrydowy, zastosuj krem z filtrem, który chroni przed
                    promieniowaniem lampy.
                    <br />
                    <span className="red">6.</span> Do sprzątania zawsze używaj
                    rękawiczek i po zakończeniu zastosuj krem o działaniu
                    odżywczym i natłuszczającym.
                    <br />
                    <span className="red">7.</span> Dopasuj krem do wieku – tak
                    jak do skóry twarzy, tak i do skóry dłoni powinniśmy
                    dobierać kremy w zależności od wieku jak i zmian na skórze.
                    <br />
                    <span className="red">8.</span> Wybieraj kremy tylko takie,
                    których skład został opracowany przy współudziale lekarzy
                    dermatologów, a działanie składników – przebadane
                    klinicznie.
                  </p>
                </div>
                <div className="row__wrapper__photo">
                  <img src="/site/assets/hand/dlonie.png" alt />
                </div>
              </div>
            </div>
            <div className="row row-blue">
              <div className="row__wrapper">
                <div className="text">
                  <h2>Pielęgnacja odpowiednia do wieku skóry</h2>
                  <p>
                    Pielęgnacja skóry młodej, która jest napięta i elastyczna,
                    to bardziej profilaktyka niż regeneracja. W tym celu powinny
                    być stosowane przede wszystkim kremy nawilżające, za
                    wyjątkiem przebywania w niskich temperaturach, bo wtedy
                    nawet młoda skóra powinna być natłuszczana, by ją
                    zabezpieczać przed szkodliwym działaniem mroźnego powietrza.{" "}
                    <br />
                    <br /> Skóra osób po 35 roku życia wymaga już nie tylko
                    nawilżania, ale również ochrony przed promieniami UV. Skóra
                    od 30. roku życia zaczyna już tracić elastyczność, łatwiej
                    też wtedy o przebarwienia. Zimą powinna być zabezpieczana
                    dobrymi kremami regenerującymi. <br />
                    <br /> Po 45. roku życia głównie stosujemy kremy odżywcze i
                    regenerujące, bo pielęgnacja takiej skóry to przede
                    wszystkim działanie polegające na jej wzmacnianiu i
                    odbudowie. W słoneczne dni, niezależnie od pory roku,
                    powinniśmy stosować krem z filtrem UV.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row__wrapper">
                <div className="text">
                  <h2
                    style={{
                      "-webkit-text-align": "center",
                      "text-align": "center"
                    }}
                  >
                    Sprawdzone składniki w pielęgnacji dłoni
                  </h2>
                  <div className="product">
                    <div className="product__item">
                      <div className="product__item__photo">
                        <img src="/site/assets/hand/products/gliceryna.png" />
                      </div>
                      <div className="product__item__info">
                        <p className="name">Gliceryna</p>
                        jest substancją o niezwykłych właściwościach
                        hydrofilowych i lipofilowych: doskonale nawilża i
                        natłuszcza, doskonale wnika do warstwy rogowej naskórka.
                        Obniża również punkt zamarzania, dlatego jest idealnym
                        składnikiem pielęgnującym skórę w niskich temperaturach.
                      </div>
                    </div>
                    <div className="product__item">
                      <div className="product__item__photo">
                        <img src="/site/assets/hand/products/b2.jpg" />
                      </div>
                      <div className="product__item__info">
                        <p className="name">Pantenol </p>
                        (prowitamina B5) ma właściwości zmiękczające, co pomaga
                        usunąć zrogowaciałe komórki naskórka oraz wspiera jego
                        regenerację i odnowę. Ułatwia to również wchłanianie
                        substancji aktywnych z kremów.
                      </div>
                    </div>
                    <div className="product__item">
                      <div className="product__item__photo">
                        <img src="/site/assets/hand/products/malina.png" />
                      </div>
                      <div className="product__item__info">
                        <p className="name">Malina nordycka </p>
                        to owoc ceniony w kosmetyce, ponieważ dostarcza skórze
                        przeciwutleniacze, witaminę C i substancje odżywcze,
                        które mają właściwości regenerujące.
                      </div>
                    </div>
                    <div className="product__item">
                      <div className="product__item__photo">
                        <img src="/site/assets/hand/products/kwiatki2.jpg" />
                      </div>
                      <div className="product__item__info">
                        <p className="name">Bisabolol</p>
                        to substancja łagodząca, redukująca powstawanie
                        podrażnień skóry. Ma to istotne znaczenie szczególnie w
                        warunkach zimowych, kiedy skóra staje się szorstka i
                        zaczerwieniona.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="hand">
            <div className="row">
              <div className="row__wrapper">
                <div className="text">
                  <h2>Pytania do naszego eksperta</h2>
                  <p>
                    <span className="red">
                      1. Jak radzić sobie z przebarwieniami na dłoniach? O czym
                      świadczą, czy można im zapobiec?
                    </span>
                    <br />
                    <br /> Przebarwienia na dłoniach powstają najczęściej pod
                    wpływem niekorzystnego działania promieni słonecznych.
                    Ryzyko przebarwień posłonecznych zwiększa się na skutek zbyt
                    intensywnego i częstego opalania się, zwłaszcza w solarium.
                    Również zwiększa je stosowanie preparatów fotouczulających
                    (hormony, niesterydowe leki przeciwzapalne, tetracykliny,
                    furosemid, retinoidy, preparaty z zielem dziurawca, niektóre
                    olejki: pomarańczowy, cynamonowy). Gorsza kondycja skóry –
                    jej przesuszenie, uszkodzony płaszcz hydrolipidowy – sprzyja
                    również powstawaniu przebarwień. <br />
                    <br /> Aby im zapobiegać, należy profilaktycznie stosować
                    kremy z filtrami UV. Jeśli już się pojawią, zajmijmy się
                    nimi w sposób szczególny. Należy używać dobrych,
                    przebadanych pod względem skuteczności kremów (krem może
                    zawierać bardzo dobre produkty, ale przy niewłaściwym ich
                    połączeniu lub braku odpowiednich substancji towarzyszących
                    jego skuteczność może być niewielka). W tym konkretnym
                    przypadku krem powinien zawierać również substancje
                    rozjaśniające. Warto również stosować na ręce peelingi,
                    które usuną zrogowaciałe komórki, co będzie działać
                    rozjaśniająco na skórę i ułatwi wchłanianie się kremu.{" "}
                    <br />
                    <br />
                    <span className="red">
                      2. Moja skóra dłoni jest zniszczona, pomarszczona. Mimo że
                      mam trzydzieści parę lat, to moje ręce przypominają dłonie
                      staruszki. Co zrobić, by je odmłodzić?
                    </span>{" "}
                    <br />
                    <br /> Prawdopodobnie skóra rąk jest bardzo odwodniona.
                    Odwodnienie, które zaburza metabolizm komórek oraz pracę i
                    funkcję ochronną płaszcza hydrolipidowego, otwiera szeroko
                    drogę na destrukcyjne działanie wolnych rodników tlenowych.
                    Dlatego proces starzenia się zachodzi w takiej skórze
                    znacznie szybciej. <br />
                    <br /> To, co na pewno trzeba wdrożyć do codziennej
                    praktyki, to kilkakrotne nakładanie kremu, każdego dnia, a
                    wieczorem umycie rąk żelem peelingującym, tzw. scrubem. W
                    tej konkretnej sytuacji powinno się stosować różne kremy na
                    przemian i zalecany jest krem zawierający prowitaminę B5 – w
                    celu jej zmiękczenia i lepszego nawilżenia – oraz krem
                    odżywczy. Mogą się tu sprawdzić kremy zawierające malinę
                    nordycką, która ma udowodnione właściwości silnie
                    odżywiające skórę. <br />
                    <br />
                    <span className="red">
                      3. Kiedy używać kremy nawilżającego, a kiedy – odżywczego?
                    </span>{" "}
                    <br />
                    <br /> Kremy nawilżające stosujemy latem, kiedy powietrze
                    jest suche i ciepłe. Kremy odżywcze stosujemy przez cały
                    rok, ale zimą są to podstawowe kremy do rąk i powinny być
                    aplikowane kilka razy dziennie. <br />
                    <br />
                    <span className="red">
                      4. Czy skóra może przyzwyczaić się do kremu do tego
                      stopnia, że krem przestanie na nią działać?
                    </span>{" "}
                    <br />
                    <br /> Raczej jest to mało prawdopodobne. Może wystąpić
                    znudzenie się danym kremem lub zapachem, ale krem będzie
                    dalej działał. <br />
                    <br />
                    <span className="red">
                      5. Jak często powinniśmy zmieniać krem do rąk?
                    </span>{" "}
                    <br />
                    <br /> Powinniśmy stosować te kremy, które działają i
                    odpowiadają naszym potrzebom (stan skóry, zapach,
                    wchłanialność). Natomiast pora roku może warunkować zmianę
                    kremu: zimą powinien to być krem odżywczy, regenerujący,
                    latem natomiast – nawilżający i rozjaśniający.
                  </p>
                  <p
                    style={{
                      "-webkit-text-align": "right",
                      "text-align": "right"
                    }}
                  >
                    <strong>
                      <br />
                      Dr Urszula Brumer
                    </strong>{" "}
                    <br />
                    Lekarz medycyny estetycznej
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="hand last">
            <div className="row">
              <div className="row__wrapper">
                <div className="row__wrapper__photo">
                  <img src="/site/assets/hand/hand-product3.png" alt />
                </div>
                <div className="text row__wrapper__text">
                  <h2>Inteligentne nawilżanie</h2>
                  <p>
                    Pierwszy Krem do rąk NEUTROGENA<sup>®</sup>&nbsp;Formuła
                    Norweska pojawił się w sprzedaży w 1972 roku i w krótkim
                    czasie zapewnił marce NEUTROGENA<sup>®</sup>&nbsp;rangę
                    kultowej.
                  </p>
                  <p>
                    Czerpiąc z rozległego doświadczenia, marka NEUTROGENA
                    <sup>®</sup>&nbsp;stworzyła kolejne produkty, które
                    odpowiadają na potrzeby konsumentów. Specjalnie opracowane
                    kremy do rąk to między innymi: skoncentrowany krem do rąk
                    (zapachowy i bezzapachowy), Intensywnie regenerujący krem do
                    pielęgnacji rąk, Szybko wchłaniający się krem do rąk dla
                    osób, którym zależy na czasie, Krem do rąk i paznokci –
                    łączący dwie korzyści w jednym – oraz Krem do rąk przeciw
                    starzeniu się skóry, który zmniejsza i rozjaśnia
                    przebarwienia.&nbsp;
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/site/o-neutrogenie/kim-jestesmy/">
                  neutrogena<sup>®</sup> obecnie
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/formula-norweska/">
                  czym jest formuła norweska?
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/historia/">
                  poznaj naszą historię
                </a>
                <span>|</span>
                <a href="/site/produkty/">zobacz produkty neutrogena</a>
              </div>
            </div>
          </div>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 01/02/2023
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(\'body\').typographer_orphan({forbidden: ["a", "i", "o", "u", "w", "z"]});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
